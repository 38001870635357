import JsBarcode from "jsbarcode";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import generateThermalPDF from "@/app/ui/modules/receipt-v2/bulk-resi-thermal/index";
import generatePDF from "@/app/ui/modules/receipt-v2/bulk-resi-pdf/index";
import { AccountController } from "@/app/ui/controllers/AccountController";
import generateThermalPDFV2 from "@/app/ui/modules/receipt-v2/thermal";
import { logoBlack } from "@/app/ui/modules/receipt-v2/constants/images";

const barcodeData = (printData: PrintDataEntitas) => {
  const barcode = document.createElement("img");
  JsBarcode(barcode, printData.sttNo, {
    textAlign: "left",
    displayValue: false,
    fontSize: 16
  });

  let vendorRefCode: any = "";
  if (printData.sttVendorRefferenceNo) {
    vendorRefCode = document.createElement("img");
    JsBarcode(vendorRefCode, printData.sttVendorRefferenceNo, {
      textAlign: "left",
      displayValue: false,
      fontSize: 16
    });
  }

  return {
    barcode,
    vendorRefCode
  };
};

const printDocMassive = (
  type: string,
  printDataMassive: Array<PrintDataEntitas>
) => {
  const listSttPiecesPrintMassive: any = [];
  printDataMassive.forEach((printData: PrintDataEntitas) => {
    printData.sttPieces.forEach(e => {
      listSttPiecesPrintMassive.push({
        ...e,
        ...printData,
        barcode: barcodeData(printData).barcode.src,
        vendorRefCode: barcodeData(printData).vendorRefCode.src
      });
    });
  });
  new Promise(function(resolve) {
    MainAppController.showLoading();
    setTimeout(resolve.bind(null, null), 1);
  }).then(() => {
    const accountType = AccountController.accountData.account_type;
    if (type === "thermal") {
      generateThermalPDF(accountType, listSttPiecesPrintMassive);
    } else {
      generatePDF(accountType, listSttPiecesPrintMassive);
    }
    MainAppController.closeLoading();
  });
};

const printDoc = (printData: PrintDataEntitas) => {
  const listSttPieces: any = [];
  printData?.sttPieces.map(e => {
    listSttPieces.push({
      ...e,
      ...printData,
      barcode: barcodeData(printData).barcode.src,
      vendorRefCode: barcodeData(printData).vendorRefCode.src
    });
  });

  new Promise(function(resolve) {
    MainAppController.showLoading();
    setTimeout(resolve.bind(null, null), 1);
  }).then(() => {
    const accountType = AccountController.accountData.account_type;
    generateThermalPDF(accountType, listSttPieces);
    MainAppController.closeLoading();
  });
};

const autoPrint = async (printData: PrintDataEntitas, language: any) => {
  const book: any = await import(
    "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
  );
  const bookingType = book.default.methods.bookingTypePayload(printData);
  const logo = logoBlack

  return generateThermalPDFV2(
    bookingType,
    AccountController.accountData.account_type,
    printData,
    barcodeData(printData).barcode.src,
    barcodeData(printData).vendorRefCode.src,
    printData.sttElexysNo || printData.sttNo,
    printData.sttVendorRefferenceNo,
    logo,
    printData.sttShipmentId,
    false,
    language,
    true,
    true
  );
};

export { printDoc, printDocMassive, autoPrint };
